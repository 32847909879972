import React, { useRef } from "react";
import { Scene, Engine } from "react-babylonjs";

import { Nullable, ShadowGenerator, Vector3, Camera } from "@babylonjs/core";
import GalleryEnvironment from "./Environments/Gallery";
import DomeEnvironment from "./Environments/Dome";

const onSceneMounted = (createdArgs: any) => {
  createdArgs.scene.imageProcessingConfiguration.exposure = 0.85;
  createdArgs.scene.imageProcessingConfiguration.contrast = 1.2;
};

const shadowCasters = ["krat2_LP1", "Krat2_LP", "Krat3_LP", "__root__"];
// const shadowCasters = [""];

const POCScene: React.FC = ({ children }) => {
  const shadowRef = useRef<Nullable<ShadowGenerator>>();
  const cameraRef = useRef<Nullable<Camera>>();
  return (
    <Engine antialias={true} adaptToDeviceRatio={true} canvasId="sample-canvas">
      <Scene onSceneMount={onSceneMounted}>
        <freeCamera
          name="camera1"
          position={new Vector3(-120, 44, 320)}
          rotation={new Vector3(0, 2.5, 0)}
          target={new Vector3(-16, 0, 0)}
          speed={10}
          angularSensibility={1000}
          ref={cameraRef}
        />

        <DomeEnvironment shadowRef={shadowRef} cameraRef={cameraRef}>
          {/* <arcRotateCamera
          name="Camera"
          target={Vector3.Zero()}
          alpha={-Math.PI / 4}
          beta={Math.PI / 2.5}
          radius={8}
          wheelPrecision={100}
          lowerRadiusLimit={2}
          upperRadiusLimit={30}
          // upperBetaLimit={Math.PI / 2}
          minZ={0.1}
        /> */}

          <hemisphericLight
            name="hemi"
            direction={new Vector3(0, -1, 0)}
            intensity={0.8}
          />
          <hemisphericLight
            name="hemi"
            direction={new Vector3(0, 1, 0)}
            intensity={0.1}
          />
          <directionalLight
            name="shadow-light"
            setDirectionToTarget={[Vector3.Zero()]}
            direction={Vector3.Zero()}
            position={new Vector3(10, 30, 10)}
            intensity={1.8}
            shadowMinZ={1}
            shadowMaxZ={20000}
          >
            <shadowGenerator
              mapSize={4096}
              useBlurExponentialShadowMap={true}
              blurKernel={100}
              darkness={0.1}
              shadowCasters={shadowCasters}
              forceBackFacesOnly={true}
              depthScale={100}
              ref={shadowRef}
            />
          </directionalLight>

          <directionalLight
            name="shadow-light"
            setDirectionToTarget={[Vector3.Zero()]}
            direction={Vector3.Zero()}
            position={new Vector3(8, 32, -32)}
            intensity={1.8}
            shadowMinZ={0}
            shadowMaxZ={20000}
          >
            <shadowGenerator
              mapSize={4096}
              useBlurExponentialShadowMap={true}
              blurKernel={100}
              darkness={0.05}
              shadowCasters={shadowCasters}
              forceBackFacesOnly={true}
              depthScale={100}
              ref={shadowRef}
            />
          </directionalLight>

          {children}
        </DomeEnvironment>
      </Scene>
    </Engine>
  );
};
export default POCScene;
