import React, { FunctionComponent, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useBoxState } from "../hooks/boxContext";
import BoxPopover from "./BoxPopover";
import BoxTitle from "./BoxTitle";

interface BoxData {
  name: string;
  type: string;
}

interface BoxDataRepo {
  [ID: string]: BoxData;
}

const boxInfo: BoxDataRepo = {
  box_0: {
    type: "5602301",
    name: "Combo Excelsior®",
  },
  box_1: {
    type: "2500461",
    name: "Magnum Optimum® 1208",
  },
  box_2: {
    type: "5602301",
    name: "Combo Excelsior®",
  },
  box_3: {
    type: "2500461",
    name: "Magnum Optimum® 1208",
  },
  box_4: {
    type: "5602301",
    name: "Combo Excelsior®",
  },
  box_5: {
    type: "2500461",
    name: "Magnum Optimum® 1208",
  },
  box_6: {
    type: "5602301",
    name: "Combo Excelsior®",
  },
  box_7: {
    type: "2500461",
    name: "Magnum Optimum® 1208",
  },
  box_8: {
    type: "5602301",
    name: "Combo Excelsior®",
  },
  box_9: {
    type: "2500461",
    name: "Magnum Optimum® 1208",
  },
  box_10: {
    type: "5602301",
    name: "Combo Excelsior®",
  },
  box_11: {
    type: "2500461",
    name: "Magnum Optimum® 1208",
  },
  box_12: {
    type: "5602301",
    name: "Combo Excelsior®",
  },
  box_13: {
    type: "2500461",
    name: "Magnum Optimum® 1208",
  },
};

const useStyles = makeStyles({
  card: {
    position: "absolute",
    top: 64,
    right: 64,
    width: 545,
  },
  title: {
    position: "absolute",
    top: 64,
    left: 64,
  },
});

const BoxInfoOverlay: React.FC = (props) => {
  const classes = useStyles();
  const { selected, highlighted } = useBoxState();
  const box = highlighted || selected;

  if (!box) {
    return <Fragment></Fragment>;
  }
  //   return <div style={{ backgroundColor: "white" }}></div>;

  return boxInfo[box] ? (
    <Fragment>
      <BoxPopover {...boxInfo[box]} classes={{ root: classes.card }} />
      {selected && (
        <BoxTitle {...boxInfo[selected]} classes={{ root: classes.title }} />
      )}
    </Fragment>
  ) : (
    <Fragment></Fragment>
  );
};
export default BoxInfoOverlay;

// {boxInfo[box].name}
