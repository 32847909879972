import "@babylonjs/loaders/glTF";
import React from "react";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./App.css";
import Scene from "./Scene";
import Spheres from "./Spheres";
import Boxes from "./Boxes";

import { BoxProvider, useBox, useBoxState } from "./hooks/boxContext";

import UIOverlay from "./UI/Overlay";

import BoxInfoOverlay from "./UI/BoxInfoOverlay";

// mirror.material.reflectionTexture = new BABYLON.MirrorTexture(
//   "mirror",
//   { ratio: 0.5 },
//   scene,
//   true
// );
// mirror.material.reflectionTexture.mirrorPlane = new BABYLON.Plane(
//   0,
//   -1.0,
//   0,
//   -2.0
// );
// mirror.material.reflectionTexture.renderList = [knot];
// mirror.material.reflectionTexture.level = 1.0;
// mirror.material.reflectionTexture.adaptiveBlurKernel = 32;

const Virtual: React.FC = () => {
  const [state, dispatch] = useBox();
  return (
    <Scene>
      <Router>
        <BoxProvider state={state} dispatch={dispatch}>
          <Boxes highPoly={false} amount={15} />
        </BoxProvider>
      </Router>
    </Scene>
  );
};

const TestUI: React.FC = () => {
  const { selected } = useBoxState();
  return <div>{selected}</div>;
};

const BridgedApp = () => {
  return (
    <BoxProvider>
      <Virtual />
      <UIOverlay>
        <BoxInfoOverlay />
      </UIOverlay>
    </BoxProvider>
  );
};

export default BridgedApp;
