import React, {
  FunctionComponent,
  createContext,
  useContext,
  Context,
  useState,
} from "react";

export type BoxStateContextType = {
  selected?: string;
  highlighted?: string;
};

export type BoxDispatchContextType = {
  setSelected: undefined | ((arg0: string | undefined) => void);
  setHighlighted: undefined | ((arg0: string | undefined) => void);
};

export const BoxStateContext: Context<
  BoxStateContextType | undefined
> = createContext<BoxStateContextType | undefined>(undefined);

export const BoxDispatchContext: Context<
  BoxDispatchContextType | undefined
> = createContext<BoxDispatchContextType | undefined>(undefined);

export type BoxProviderType = {
  state?: BoxStateContextType;
  dispatch?: BoxDispatchContextType;
};

const BoxProvider: FunctionComponent<BoxProviderType> = ({
  state,
  dispatch,
  children,
}) => {
  const [selected, setSelected] = useState<string>();
  const [highlighted, setHighlighted] = useState<string>();

  return (
    <BoxStateContext.Provider value={state || { selected, highlighted }}>
      <BoxDispatchContext.Provider
        value={
          dispatch || {
            setSelected,
            setHighlighted,
          }
        }
      >
        {children}
      </BoxDispatchContext.Provider>
    </BoxStateContext.Provider>
  );
};

function useBoxState() {
  const context = useContext(BoxStateContext);
  if (context === undefined) {
    throw new Error("useBoxState must be used within a BoxProvider");
  }
  return context;
}

function useBoxDispatch() {
  const context = useContext(BoxDispatchContext);
  if (context === undefined) {
    throw new Error("useBoxDispatch must be used within a BoxProvider");
  }
  return context;
}

function useBox(): [BoxStateContextType, BoxDispatchContextType] {
  return [useBoxState(), useBoxDispatch()];
}

export { BoxProvider, useBoxState, useBoxDispatch, useBox };
