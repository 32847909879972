import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

import {
  Nullable,
  Scene,
  SceneLoader,
  ShadowGenerator,
  Camera,
  serializeAsCameraReference,
  RenderTargetTexture,
  Mesh,
  BaseTexture,
  Texture,
} from "@babylonjs/core";

import { useEngine, useCanvas, useScene } from "react-babylonjs";

import { EnvironmentContext } from "../hooks/environment";

export type EnvironmentProps = {
  shadowRef: React.MutableRefObject<Nullable<ShadowGenerator> | undefined>;
  cameraRef: React.MutableRefObject<Nullable<Camera> | undefined>;
  rootUrl: string;
  sceneFilename: string;
  size?: number;
  envUrl?: string;
  envSize?: number;
  onLoad?: (arg0: Scene) => RenderTargetTexture | null;
  onBoxAdd?: (arg0: Scene, arg1: Mesh) => void;
};

// var hdrTextureClone: Nullable<BaseTexture>;
const Environment: FunctionComponent<EnvironmentProps> = (props) => {
  const {
    shadowRef,
    cameraRef,
    rootUrl,
    sceneFilename,
    children,
    envUrl,
    envSize,
    size,
    onLoad,
    onBoxAdd,
  } = props;

  const scene = useScene();

  // const [texture, setTexture] = useState(null);
  const [textureReady, setTextureReady] = useState(false);
  const [envReady, setEnvReady] = useState(false);

  const engine = useEngine();
  const canvas = useCanvas();
  const sceneRef = useRef<Nullable<Scene>>(null);
  const textureRef = useRef<Nullable<RenderTargetTexture>>(null);

  useEffect(() => {
    SceneLoader.Append(rootUrl, sceneFilename, scene, function (newScene) {
      newScene.shadowsEnabled = true;
      const texture = !!onLoad && onLoad(newScene);

      scene!.activeCamera!.attachControl(canvas, true);
      // newScene.activeCamera = scene!.activeCamera;
      // newScene?.activeCamera?.attachControl(canvas, true);
      sceneRef.current = newScene;
      // sceneRef.set(newScene);
      //   current = newScene;

      // for (var i = 0; i < newScene.meshes.length; i++) {
      //   newScene.meshes[i].receiveShadows = true;
      //   // newScene.meshes[i].material = myMaterial;
      //   if (shadowRef && shadowRef.current) {
      //     shadowRef!
      //       .current!.getShadowMap()!
      //       .renderList!.push(newScene.meshes[i]);
      //   }
      // }
      // newScene.lights.forEach((light) => {
      //   if (light && light.getShadowGenerator()) {
      //     console.log(light);
      //     light.dispose();
      //     // light.getShadowGenerator()!.getShadowMap()!.refreshRate = 1;
      //   }
      // });

      setEnvReady(true);
      if (texture) {
        textureRef.current = texture;
      }
    });
  }, []);

  const onLoaded = () => {};

  const hdrTexture = useRef<BaseTexture>();
  const hdrTextureRef = useCallback((node) => {
    if (node) {
      console.log("hdrTexture", node);
      hdrTexture.current = node;

      setTextureReady(true); // trigger render and props assignment
      // hdrTextureClone = hdrTexture!.clone();
      // hdrTextureClone!.coordinatesMode = Texture.SKYBOX_MODE;
    }
  }, []);

  const ready = textureReady && envReady;

  return (
    <EnvironmentContext.Provider
      value={{
        scene: sceneRef.current as Scene,
        cubeTexture: hdrTexture.current || (textureRef.current as BaseTexture),
        ready,
        shadowGenerator: shadowRef.current,
        camera: cameraRef.current,
        onBoxAdd,
      }}
    >
      {envUrl && envSize && (
        <equiRectangularCubeTexture
          url={envUrl}
          ref={hdrTextureRef}
          name="hdrTexture"
          size={envSize}
        />
      )}
      {ready && children}
    </EnvironmentContext.Provider>
  );
};
export default Environment;
