import React, { FunctionComponent, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  IconButton,
} from "@material-ui/core";

import DetailsIcon from "@material-ui/icons/Add";
import FavoriteIcon from "@material-ui/icons/Favorite";

const useStyles = makeStyles({
  root: {
    pointerEvents: "auto",
    maxWidth: 345,
    dropShadow: "none",
    backgroundColor: "rgba(255,255,255,0.6)",
    borderRadius: 24,
  },
  paper: {},
});

export type BoxProps = {
  name: string;
  type: string;
  classes: any;
};

const BoxPopover: React.FC<BoxProps> = ({ name, type, classes: _classes }) => {
  const classes = useStyles({ classes: _classes });
  return (
    <Card classes={{ root: classes.root }} elevation={0}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {type}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <IconButton size="small" color="primary">
          <FavoriteIcon />
        </IconButton>
        <Button size="small" color="primary" startIcon={<DetailsIcon />}>
          Bekijk details
        </Button>
      </CardActions>
    </Card>
  );
};
export default BoxPopover;
