import React, { FunctionComponent } from "react";
import {
  Nullable,
  ShadowGenerator,
  Scene,
  Plane,
  StandardMaterial,
  MirrorTexture,
  PBRMaterial,
  ReflectionProbe,
  GlowLayer,
  Color3,
  Camera,
  RenderTargetTexture,
  Constants,
  Mesh,
} from "@babylonjs/core";

import Environment from "./Environment";
import { PBRSpecularGlossinessMaterial } from "react-babylonjs";

const defaultProps = {
  rootUrl: "/assets/env/",
  sceneFilename: "scifi2.glb",
  // envUrl: "/assets/360/SpecularHDR.dds",
  envUrl: "/assets/360/dome.png",
  envSize: 256,
};

export type EnvironmentProps = {
  shadowRef: React.MutableRefObject<Nullable<ShadowGenerator> | undefined>;
  cameraRef: React.MutableRefObject<Nullable<Camera> | undefined>;
};

const DomeEnvironment: FunctionComponent<EnvironmentProps> = ({
  children,
  shadowRef,
  cameraRef,
}) => {
  const onBoxAdd = (scene: Scene, box: Mesh) => {
    const floor = scene.getMeshByName("env");
    ((floor!.material as PBRMaterial)!
      .reflectionTexture! as RenderTargetTexture).renderList!.push(box);
  };
  const onLoad = (newScene: Scene): RenderTargetTexture | null => {
    const floor = newScene.getMeshByName("env");

    // var floorProbe = new ReflectionProbe("floorProbe", 1024, newScene);

    const envMaterial = new PBRMaterial("floor", newScene);
    floor!.receiveShadows = true;
    // envMaterial.reflectionTexture = probe.cubeTexture;
    // envMaterial.roughness = 0.009;
    // envMaterial.metallic = 0.1;
    // envMaterial.albedoColor = Color3.White();
    envMaterial.microSurface = 0.96;
    envMaterial.albedoColor = new Color3(224 / 255, 255 / 255, 252 / 255);
    envMaterial.reflectivityColor = new Color3(0.03, 0.03, 0.03);
    // envMaterial.reflectivityColor = new Color3(0.03, 0.03, 0.03);
    const rt = new MirrorTexture("mirror", { ratio: 0.5 }, newScene, true);
    rt.mirrorPlane = new Plane(0, -1.0, 0, -2.0);
    rt.renderList = [];
    rt.level = 2.0;
    rt.adaptiveBlurKernel = 32;

    envMaterial.reflectionTexture = rt;
    // envMaterial.realTimeFiltering = true;
    // envMaterial.realTimeFilteringQuality =
    //   Constants.TEXTURE_FILTERING_QUALITY_HIGH;

    floor!.material = envMaterial;

    const gl = new GlowLayer("glow", newScene);
    gl.intensity = 1.5;

    const bench1 = newScene.getMeshByName("bench_primitive0");
    const bench2 = newScene.getMeshByName("bench_primitive2");
    const bench4 = newScene.getMeshByName("bench_primitive4");
    const bench6 = newScene.getMeshByName("bench_primitive6");
    const bench8 = newScene.getMeshByName("bench_primitive8");

    const shiny1 = newScene.getMeshByName("bench_primitive1");
    const shiny2 = newScene.getMeshByName("bench_primitive3");
    const shiny4 = newScene.getMeshByName("bench_primitive5");
    const shiny6 = newScene.getMeshByName("bench_primitive7");

    var probe = new ReflectionProbe("environmentProbe", 512, newScene);
    const glossy = [bench1, bench2, bench4, bench6, bench8];
    const glowy = [shiny1, shiny2, shiny4, shiny6];

    for (var index = 0; index < glossy.length; index++) {
      probe.renderList!.push(glossy[index]!);
      rt.renderList!.push(glossy[index]!);
    }

    // console.log(floor);
    const gloss = new PBRMaterial("mirror", newScene);
    gloss.microSurface = 0.96;
    gloss.albedoColor = new Color3(224 / 255, 255 / 255, 252 / 255);
    gloss.reflectivityColor = new Color3(0.03, 0.03, 0.03);

    const glow = new StandardMaterial("shine", newScene);
    glow.emissiveColor = new Color3(199 / 255, 30 / 255, 18 / 255);
    // glow.emissiveColor = new Color3(79 / 255, 13 / 255, 9 / 255);
    glow.ambientColor = new Color3(199 / 255, 30 / 255, 18 / 255);

    glossy.forEach((m) => {
      // m!.receiveShadows = true;
      m!.material = gloss;
    });

    glowy.forEach((m) => {
      m!.material = glow;
    });

    // Fog
    // newScene.fogMode = Scene.FOGMODE_LINEAR;
    // // newScene.fogColor = newScene.clearColor;
    // newScene.fogStart = 20.0;
    // newScene.fogEnd = 50.0;

    // floor!.material.reflectionTexture = new MirrorTexture(
    //   "mirror",
    //   { ratio: 0.5 },
    //   newScene,
    //   true
    // );
    // floor!.material.reflectionTexture.mirrorPlane = new Plane(0, -1.0, 0, -2.0);
    // // floor!.material.reflectionTexture.renderList = [knot];
    // floor!.material.reflectionTexture.level = 1.0;
    // floor!.material.reflectionTexture.adaptiveBlurKernel = 32;
    // newScene.skipFrustumClipping = true;

    return probe.cubeTexture;
  };
  return (
    <Environment
      shadowRef={shadowRef}
      cameraRef={cameraRef}
      {...defaultProps}
      onLoad={onLoad}
      onBoxAdd={onBoxAdd}
    >
      {children}
    </Environment>
  );
};
export default DomeEnvironment;
