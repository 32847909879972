import { createContext, useContext, Context } from "react";

import {
  Nullable,
  Scene,
  ShadowGenerator,
  Camera,
  BaseTexture,
  Mesh,
} from "@babylonjs/core";

export type EnvironmentContextType = {
  scene: Nullable<Scene>;
  cubeTexture: BaseTexture | null;
  ready: boolean;
  shadowGenerator: Nullable<ShadowGenerator> | undefined;
  camera: Nullable<Camera> | undefined;
  onBoxAdd?: (arg0: Scene, arg1: Mesh) => void;
};

export const EnvironmentContext: Context<EnvironmentContextType> = createContext<
  EnvironmentContextType
>({
  scene: null,
  ready: false,
  cubeTexture: null,
  shadowGenerator: null,
  camera: null,
  onBoxAdd: undefined,
});

/**
 * Get the Environment Scene from the context.
 */
export const useEnvironment = (): EnvironmentContextType =>
  useContext(EnvironmentContext);
