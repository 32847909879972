import React, { FunctionComponent, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    // pointerEvents: "auto",
  },
});

export type BoxProps = {
  name: string;
  type: string;
  classes: any;
};

const BoxTitle: React.FC<BoxProps> = ({ name, type, classes: _classes }) => {
  const classes = useStyles({ classes: _classes });
  return (
    <Box className={classes.root} flex>
      <Typography gutterBottom variant="h1" component="h2">
        {name}
      </Typography>
      <Typography variant="h3" color="textSecondary" component="p">
        {type}
      </Typography>
    </Box>
  );
};
export default BoxTitle;
