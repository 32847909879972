import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Vector3,
  Texture,
  SceneLoader,
  StandardMaterial,
  PBRMaterial,
  Color3,
  Nullable,
  Mesh,
  TransformNode,
  Animation,
  EasingFunction,
  CubicEase,
  BaseTexture,
  FresnelParameters,
} from "@babylonjs/core";

import { useScene, useHover, useBeforeRender } from "react-babylonjs";

import { useEnvironment } from "./hooks/environment";

import { useClick } from "./hooks/useClick";

export type BoxProps = {
  cubeTexture: BaseTexture | null;
  name: string;
  highPoly: boolean;
  selected: boolean;
  onSelect?: (arg0: Vector3) => void;
  onLoad?: (arg0: Mesh | null) => void;
  highlighted: boolean;
  onHighlight?: (arg0: Mesh | null) => void;
  scaleTo?: number;
  center?: Vector3;
  progressRotation?: Vector3;
  modelRotation?: Vector3;
};

const defaultProps = {
  scaleTo: 10,
  center: new Vector3(0, 0, 0),
};

const Box: FunctionComponent<BoxProps> = (argProps) => {
  const props = { ...defaultProps, ...argProps };
  const {
    highPoly,
    name,
    selected,
    onSelect,
    highlighted,
    onHighlight,
    scaleTo,
    center,
    modelRotation,
    cubeTexture,
    onLoad,
    ...other
  } = props;

  // console.log(highPoly);

  // const { texture, ready } = useSkybox();
  const { scene, ready } = useEnvironment();

  const [meshLoaded, setMeshLoaded] = useState(false);
  const lpMeshRef: React.MutableRefObject<Nullable<Mesh>> = useRef(null);
  const hpMeshRef: React.MutableRefObject<Nullable<Mesh>> = useRef(null);
  const [lpContainer, setLPContainer] = useState<TransformNode | null>(null);
  const [hpContainer, setHPContainer] = useState<TransformNode | null>(null);

  const meshRef = highPoly ? hpMeshRef : lpMeshRef;
  const container = highPoly ? hpContainer : lpContainer;

  // useClick(
  //   () => {
  //     // console.log("clicked box");
  //     if (onSelect) {
  //       onSelect!();
  //     }
  //   },
  //   [meshLoaded],
  //   hpMeshRef
  // );

  useClick(
    () => {
      // console.log("clicked box");
      // console.log(scene?.activeCamera?.position);
      // console.log(scene?.activeCamera?.absoluteRotation);
      if (onSelect) {
        onSelect!(lpMeshRef.current!.absolutePosition);
      }
    },
    [meshLoaded],
    lpMeshRef
  );

  useHover(
    () => onHighlight && onHighlight(lpMeshRef.current as Mesh),
    () => onHighlight && onHighlight(null),
    lpMeshRef
  );

  const rpm = 5;
  // useBeforeRender((scene) => {
  //   // console.log("render frame", meshRef.current);
  //   if (meshRef.current) {
  //     // Delta time smoothes the animation.
  //     var deltaTimeInMillis = scene.getEngine().getDeltaTime();
  //     // console.log(deltaTimeInMillis);
  //     if (hovered) {
  //       meshRef.current.rotation.y +=
  //         (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
  //     }
  //   }
  // });

  useEffect(() => {
    // console.log(hovered, highPoly, container, lpContainer);
    const cb = () => {
      if (container) {
        let deltaTimeInMillis = scene!.getEngine().getDeltaTime();
        container!.rotation.y +=
          (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
      }
    };
    // const handler = scene!.registerBeforeRender(cb);
    if (selected && highlighted && !highPoly) {
      const handler = scene!.registerBeforeRender(cb);
    } else {
      scene?.unregisterBeforeRender(cb);
    }
    return () => {
      scene?.unregisterBeforeRender(cb);
    };
  }, [
    lpContainer,
    hpContainer,
    container,
    selected,
    highlighted,
    highPoly,
    meshLoaded,
  ]);

  useEffect(() => {
    if (ready) {
      // console.log(highPoly, hpMeshRef.current);
      if (
        (!highPoly && !lpMeshRef.current) ||
        (highPoly && !hpMeshRef.current)
      ) {
        SceneLoader.ImportMesh(
          "",
          modelProps.rootUrl,
          `${name}_${highPoly ? "HP" : "LP"}.glb`,
          scene,
          function (meshes) {
            var root = new TransformNode(`grouped_${name}`);
            meshes.forEach((mesh) => {
              // leave meshes already parented to maintain model hierarchy:
              if (!mesh.parent) {
                mesh.parent = root;
              }
            });

            const factor = scaleTo / (highPoly ? 40 : 40);
            // const m = meshes[1] as Mesh;
            root.scaling = new Vector3(factor, factor, factor);
            root.position = center;

            // m.scaling = new Vector3(factor, factor, factor);
            // m.position = center;

            // if (modelRotation !== undefined) {
            //   root.rotation = modelRotation!;
            // }
            if (!highPoly) {
              var mat = meshes[1].material as StandardMaterial;

              // var mat = new StandardMaterial("box_material" + name, scene!);
              // mat.diffuseColor = Color3.White();

              // var mat = new PBRMaterial("box_pbr" + name, scene!);
              // mat.microSurface = 0.2;
              // mat.reflectivityColor = new Color3(0.85, 0.85, 0.85);
              // mat.albedoColor = new Color3(0.8, 0.2, 0.2);

              var bumpMap = new Texture(
                `${modelProps.rootUrl}${name}_normals.png`,
                scene
              );
              // meshes.forEach((mesh) => {
              mat.bumpTexture = bumpMap;
              if (cubeTexture) {
                mat.reflectionTexture = cubeTexture;
                mat.reflectionTexture.level = 0.5;

                mat.reflectionFresnelParameters = new FresnelParameters();
                mat.reflectionFresnelParameters.bias = 0.2;
              }
              meshes[1].material = mat;
              // meshes[1].receiveShadows = true;
              // scene?.lights.forEach((light) => {
              //   light
              //     .getShadowGenerator()
              //     ?.getShadowMap()
              //     ?.renderList?.push(meshes[1]);
              //   console.log(
              //     light.getShadowGenerator()?.getShadowMap()?.renderList
              //   );
              //   // light.getShadowGenerator()!.getShadowMap()!.refreshRate = 1;
              // });
              // });
            }

            if (highPoly) {
              hpMeshRef!.current = meshes[0] as Mesh;
              setHPContainer(root);
            } else {
              // console.log(highPoly, meshes[1]);
              lpMeshRef!.current = meshes[1] as Mesh;
              setLPContainer(root);
              onLoad && onLoad(lpMeshRef!.current);
            }
            setMeshLoaded(true);
            //   setMesh(() =>m);
            // scene!.createDefaultCameraOrLight(true, true, true);
            // scene!.createDefaultEnvironment();
          }
        );
      }
    }
    if (highPoly) {
      if (hpContainer && lpContainer) {
        hpContainer!.rotation = lpContainer!.rotation;
      }
      hpContainer?.setEnabled(true);
      lpContainer?.setEnabled(false);
    } else {
      hpContainer?.setEnabled(false);
      lpContainer?.setEnabled(true);
    }
    return () => {
      //TODO: destroy
      // hpMeshRef?.current?.dispose();
      // lpMeshRef?.current?.dispose();
    };
  }, [ready, highPoly]);

  if (!ready || !scene) return <Fragment></Fragment>;

  //   const [mood, setMood] = useState(0);
  //   const switchMood = useKeys(["m"]);
  //   useEffect(() => {
  //     setMood((mood) => (mood + 1) % moods.length);
  //   }, [switchMood]);
  //   console.log(mood, moods[mood]);

  const modelProps = {
    rootUrl: `/assets/boxes/`,
    ...other,
  };

  // var mat = new StandardMaterial("mat", scene!);
  // var bumpMap = new Texture(`${modelProps.rootUrl}${name}_normals.png`, scene);
  // mat.ambientColor = new Color3(0.2, 0.2, 0.2);
  // mat.bumpTexture = bumpMap;

  // var pbrMat = new PBRMaterial("pbrMat", scene!);
  // if (!highPoly) {
  //   pbrMat.bumpTexture = new Texture(
  //     `${modelProps.rootUrl}${name}_normals.png`,
  //     scene
  //   );
  // }
  // //   pbrMat.environmentIntensity = 0.8;
  // //   pbrMat.specularIntensity = 0.2;
  // pbrMat.reflectivityColor = new Color3(0.0025, 0.0025, 0.0025);
  // pbrMat.albedoColor = new Color3(0.05, 0.05, 0.05);
  // //   pbrMat.useMicroSurfaceFromReflectivityMapAlpha = true;
  // pbrMat.microSurface = 0.96;
  // pbrMat.reflectionTexture = texture ?? null;

  //   pbrMat.albedoColor = new Color3(0.2, 0.2, 0.2);

  //   return <box name="plane" width={scaleTo} height={scaleTo} depth={scaleTo} />;
  return <Fragment></Fragment>;
};
export default Box;
