import { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  Nullable,
  Observer,
  EventState,
  ActionManager,
  ActionEvent,
  ExecuteCodeAction,
  Mesh,
  IAction,
  AbstractMesh,
} from "@babylonjs/core";

export type MeshEventType = {
  (ev: ActionEvent): void;
};

/**
 * useClick hook
 *
 * @param onClick What would be passed in the OnPickTrigger from ActionManager
 * @param ownRef to re-use a Ref you already have, otherwise one is created for you and returned.
 */
export function useClick(
  onClick: MeshEventType,
  deps?: Array<any>,
  ownRef?: MutableRefObject<Nullable<Mesh>>
): [MutableRefObject<Nullable<Mesh>>] {
  const createdRef = useRef<Nullable<Mesh>>(null);
  const ref = ownRef ?? createdRef;

  useEffect(() => {
    // console.log(ref.current);
    if (ref.current) {
      if (ref.current instanceof AbstractMesh) {
        const mesh = ref.current as Mesh;

        if (!mesh.actionManager) {
          mesh.actionManager = new ActionManager(mesh.getScene());
        }

        const action: Nullable<IAction> = mesh.actionManager.registerAction(
          new ExecuteCodeAction(ActionManager.OnPickTrigger, function (
            ev: any
          ) {
            onClick(ev);
          })
        );
        return () => {
          // unregister on teardown
          mesh.actionManager?.unregisterAction(action!);
        };
      } else {
        console.warn("onClick hook only supports referencing Meshes");
      }
    }
  }, [ref, ...(deps ?? [])]);
  // todo: if use ref.current as dep,  duplicate register action.

  return [ref];
}
