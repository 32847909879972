import React from "react";
import ReactDOM from "react-dom";

class UIOverlay extends React.Component {
  render() {
    return ReactDOM.createPortal(
      this.props.children,
      document.getElementById("ui-overlay")!
    );
  }
}

export default UIOverlay;
