import React, { useState, useEffect, Fragment, useMemo } from "react";

import {
  Vector3,
  Animation,
  EasingFunction,
  CubicEase,
  FreeCamera,
  HighlightLayer,
  Color3,
} from "@babylonjs/core";

import { useEnvironment } from "./hooks/environment";
import { useBox } from "./hooks/boxContext";

import useKeys from "./hooks/useKeys";
import Box from "./Box";
import Box2 from "./Box2";
import { TargetCamera, useCanvas } from "react-babylonjs";

const containers = ["krat2", "krat3"];

export type BoxesProps = {
  highPoly?: boolean;
  amount?: number;
};

const defaultProps = {
  highPoly: false,
  amount: 40,
};

const Boxes: React.FC<BoxesProps> = (argProps) => {
  const props = { ...defaultProps, ...argProps };
  const { highPoly, amount } = props;

  // const [selected, setSelected] = useState<String | null>();
  // const [highlighted, setHighlighted] = useState<String | null>();

  const [{ selected, highlighted }, { setSelected, setHighlighted }] = useBox();

  const { scene, ready, camera: _c, cubeTexture, onBoxAdd } = useEnvironment();
  const canvas = useCanvas();
  const camera = _c as FreeCamera;
  // const camera = scene?.activeCamera;
  useEffect(() => {
    Object.defineProperty(camera, "_setTarget", {
      set: function (v) {
        this.setTarget(v);
      },
    });
  }, []);

  var easingFunction = new CubicEase();
  easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

  var hl1 = new HighlightLayer("hl1", scene!, {
    camera: camera,
    // isStroke: true,
    blurHorizontalSize: 0.6,
    blurVerticalSize: 0.6,
    alphaBlendingMode: 2,
  });

  const animCameraPosition = new Animation(
    "animCameraPosition",
    "position",
    30,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );
  const animCameraTarget = new Animation(
    "animCameraTarget",
    "_setTarget",
    30,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );
  animCameraTarget.setEasingFunction(easingFunction);
  animCameraPosition.setEasingFunction(easingFunction);

  const targetCamera = (_position: Vector3) => {
    var pos = camera.position;
    var position = _position.add(new Vector3(0, 18, 0));
    var direction = pos.subtract(position);
    direction.normalize();

    var move = position.add(direction.multiplyByFloats(80, 80, 80));

    move._y = 48;

    var positionKeys = [
      {
        frame: 0,
        value: pos,
      },
      {
        frame: 100,
        value: move,
      },
    ];
    animCameraPosition.setKeys(positionKeys);

    var target = camera!.getTarget();
    var targetKeys = [
      {
        frame: 0,
        value: target,
      },
      {
        frame: 100,
        value: position,
      },
    ];

    animCameraTarget.setKeys(targetKeys);

    camera!.animations = [];

    camera!.animations.push(animCameraPosition);
    camera!.animations.push(animCameraTarget);
    camera!.detachControl(canvas);

    scene!.beginDirectAnimation(
      camera,
      camera!.animations,
      0,
      360,
      false,
      1,
      () => {
        camera!.attachControl(canvas, true);
        // camera!.lockedTarget = null;
      }
    );
  };

  const coords = [
    // new Vector3(-6, 0, -2),
    // new Vector3(-4, 0, -2),
    // new Vector3(-2, 0, -2),
    // new Vector3(0, 0, -2),
    // new Vector3(-6, 0, 0),
    new Vector3(-4, 0, 0),
    new Vector3(-2, 0, 0),
    new Vector3(0, 0, 0),
    new Vector3(2, 0, 0),
    new Vector3(4, 0, 0),
    new Vector3(6, 0, 0),
    new Vector3(0, 0, 2),
    new Vector3(-2, 0, 3),
    new Vector3(-4, 0, 3),
    new Vector3(-6, 0, 3),
    new Vector3(-8, 0, 3),
    new Vector3(-10, 0, 3),
    new Vector3(-12, 0, 3),
    new Vector3(-12, 0, 5),
    new Vector3(-12, 0, 7),
    new Vector3(-12, 0, 9),
    new Vector3(-12, 0, 11),
    new Vector3(-10, 0, 7),
    new Vector3(-10, 0, 9),
    new Vector3(-10, 0, 11),
    new Vector3(0, 0, 4),
    new Vector3(0, 0, 6),
    new Vector3(3, 0, 6),
    new Vector3(6, 0, 9),
    new Vector3(3, 0, 12),
    new Vector3(2, 2.63, 14),
    new Vector3(2, 2.63, 12),
    new Vector3(4, 2.63, 14),
    new Vector3(4, 2.63, 12),
    new Vector3(6, 2.63, 14),
    new Vector3(6, 2.63, 12),
    new Vector3(8, 2.63, 14),
    new Vector3(8, 2.63, 12),
    new Vector3(10, 2.63, 14),
    new Vector3(10, 2.63, 12),
    new Vector3(2, 2.63, 14),
    new Vector3(2, 2.63, 12),
  ];

  const types = ["krat2", "krat3"];

  const boxes = useMemo(
    () =>
      coords.slice(0, Math.min(amount, coords.length)).map((center, i) => ({
        type: types[(i + 1) % types.length],
        key: `box_${i}`,
        center: center.subtract(new Vector3(0, 0.05, 0)).scale(40),
        rotation: new Vector3(0, 20 - Math.random() * 40, 0),
      })),
    []
  );

  return (
    <Fragment>
      {boxes.map(({ key, type, center, rotation }) => (
        <Box2
          cubeTexture={cubeTexture}
          key={key}
          name={type}
          highPoly={false}
          center={center}
          modelRotation={rotation}
          onSelect={(position) => {
            // selected === key ? setSelected("") : setSelected(key)
            setSelected!(key);
            targetCamera(position);
          }}
          selected={key === selected}
          onHighlight={(box) => {
            setHighlighted!(box ? key : undefined);
            hl1.removeAllMeshes();
            if (box) {
              hl1.addMesh(box, Color3.White());
            }
          }}
          highlighted={key === highlighted}
          onLoad={(box) => {
            if (box) {
              onBoxAdd && onBoxAdd(scene!, box);
            }
          }}
        />
      ))}
    </Fragment>
  );
};
export default Boxes;
